
#map {
	height: 100vh;
	width: 100vw;
}






// Leaflet controls
.leaflet-top.leaflet-left {
	//top: 50px;
}

.leaflet-container .leaflet-control-attribution {
	//display: none;

	a {
		color: $color-blue-dark;
	}

}


// Popup width
.leaflet-popup {
	width: 300px;
}
