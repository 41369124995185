// Reset

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html,
body {
	height: 100vh;
	width: 100vw;
}

a {
	text-decoration: none;
}

ul {
	list-style: none;
}
