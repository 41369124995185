// Off canvas navigation
.wrapper {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
}

nav#menu {

	ul {
		width: 100%;
		height: 100%;
		background: gray;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 0;

		li {
			width: 200px;

			border-bottom: 1px solid #000;

			a {
				padding: 1em;
				color: white;
				display: block;

				&:hover {
					color: black;
				}

			}

		}

	}

}

// Nav
.nav-icon {

	position: absolute;
	clip: rect(0, 0, 0, 0);
}

label[for="nav-icon"] {
	position: fixed;
	left: 15px;
	top: 15px;
	z-index: 2;
	cursor: pointer;

	height: 20px;
	width: 20px;
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' width='30px' height='30px' viewBox='0 0 30 30' enable-background='new 0 0 30 30' xml:space='preserve'><rect width='30' height='6'/><rect y='24' width='30' height='6'/><rect y='12' width='30' height='6'/></svg>");
	background-size: cover


}

/* Make the Magic Happen */
.nav-icon + label, .wrapper {
	-webkit-transition: left 0.2s;
	transition: left 0.2s;
}

.nav-icon:checked + label {
	left: 215px;
}

.nav-icon:checked ~ .wrapper {
	left: 200px;
}
