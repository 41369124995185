.leaflet-control-minZoomIndicator {
	width: 250px;
	font-size: 1.2em;
	background: #ffffff;
	background-color: rgba(255,255,255,0.9);
	border-radius: 4px;
	padding: 4px;
	// opacity: 0.5;
	position: absolute;
	top: 6px;
	left: 40px;
}
